<template>
  <div class="home">

    <!-- <el-carousel class="my-splice" direction="vertical" :autoplay="true">
      <el-carousel-item v-for="item in 3" :key="item">
        <img class="lbImg" src="@/assets/home/lunimg.webp" />
      </el-carousel-item>
    </el-carousel>

    <div class="layers">
      <img src="@/assets/home/image-1028.webp" />
      <img src="@/assets/home/image-1029.webp" />
    </div>
    <img class="appDownTitleImg" src="@/assets/home/titleappDown.webp" /> -->

    <!-- <div class="wybzdss">
      <img class="wybzdImg" src="@/assets/home/downloadAll.webp" />
      <div class="wybzdRight">
        <div class="wybzd-tit">观赛事·看直播</div>
        <div class="wybzd-con">顶尖技术团队斥资打造一体化原生APP，拥有丰富且精致的平台游戏体验完美流畅的操作，立即下载，从容畅玩。</div>
        <div class="wybzdRight-bottom">
          <div class="wybz-right-bottom-demo">
            <div class="wybz-bom-in">
              <img class="erwm" src="@/assets/home/erwm.svg" />
              <div class="ewmTxt">扫码下载APP</div>
              <div class="ewmTxt ">iOS & Android</div>
            </div>
          </div>

          <div class="wybz-right-bottom-demo">
            <div class="wybz-bom-in bom-in-padd">
              <img class="erwm" src="@/assets/home/wxxz.svg" />
              <div class="ewmTxt">无需下载直接访问</div>
              <div class="ewmlink " @click="goLine()">https://www.beibao.com</div>
            </div>
          </div>


        </div>
      </div>
    </div> -->
    <div class="homeContent" :style="{ 'padding': isExpand ? '20px 44px' : '20px 104px' }">
      <!-- <el-carousel  :interval="4000" type="card" height="285px">
        <el-carousel-item class="lunboList" v-for="(item,index) in images" :key="index"  @click.stop="goLine(item.link)">
          <img :src="item.pcImg" draggable="false" />
        </el-carousel-item>
      </el-carousel> -->


      <!-- <el-carousel indicator-position="outside" height="160px" :interval="lbTime" :autoplay="autoplay">
        <el-carousel-item v-for="(item, index) in images" :key="index">
          <div class="lunboOut">
            <div class="lunboList" v-for="(x, j) in item" :key="j" @click.stop="goLine(x.link)">
              <img :src="x.pcImg" draggable="false" />
            </div>
          </div>
        </el-carousel-item>
      </el-carousel> -->
      
      <div class="lbOut" id="navs">

        <div class="lbDemo" v-for="(item, index) in images" :key="index" @click.stop="goLine(item.link)">
          <img :src="item.pcImg" draggable="false" />
        </div>
      </div>




      <div class="placeholder-tabs">
        <div class="tabOut">
          <div class="tabs">
            <div class="wrapper" ref="container">

              <div class="item" v-for="(item, i) in tabsList" :ref="'itemTab-' + i" :key="i"
                :class="{ active: tabs == i }" @click="changeTabs(i)">
                <!-- :src="item.icon" -->
                <img class="tabImg" :src="require(`@/assets/home/tab/tabIcon/tab${tabs == i ? 'B' : 'H'}${i}.png`)" />
                <span>{{ item.name }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div ref="scrollContainer">
        <div v-for="(item, index) in list" :key="index" v-show="tabs == index">
          <games-demo :name="item.name" ref="sportsRef"
            v-if="(item.name == '小游戏' || item.name == '区块链' || item.name == '热门') && item.platform" :icon="item.icon"
            :tabs="tabs" :code="item.platform[0].code" :upName="item.platform[0].name" :id="item.platform[0].id" />
          <sports-demo :name="item.name" :list="item.platform" ref="sportsRef" v-else :icon="item.icon" :tabs="tabs" />
        </div>
      </div>





    </div>

    <Footer noBom />

  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'
import {
  homeBanner, platformList,
} from "@/request/api";
import Footer from '@/components/Footer.vue'
import { mapState } from "vuex";
import SportsDemo from "@/components/sports.vue";
import GamesDemo from "@/components/games.vue";
export default {
  name: 'HomeView',
  components: {
    Footer,
    SportsDemo,
    GamesDemo
  },
  data() {
    return {
      images: [],
      tabsList: [{ id: 0, name: "热门", icon: require("@/assets/home/tab/tabIcon/tabB0.png"), platform: [{ id: -1 }], }],
      list: [{ id: 0, name: "热门", icon: require("@/assets/home/tab/tabIcon/tabB0.png"), platform: [{ id: -1 }], }],
      tabs: 0,
      scrollLeft: 0,
      autoplay: true,
      lbTime: 5000,
    }
  },
  computed: {
    ...mapState({
      isExpand: (state) => state.isExpand,
    }),
  },
  watch: {
    tabs(newValue) {
      this.$nextTick(() => {
        if (this.$refs.sportsRef) {
          if (newValue == 0) {
            return;
          }
          this.$refs.sportsRef[newValue].setFold();
        }
      });
    },
  },
  mounted() {
    this.getPlatformList();
    this.getHomeBanner();
    if (this.$route.query.tabs) {
      this.tabs = this.$route.query.tabs;
    }
    this.scrollInit();
  },
  methods: {
    goLine(link) {
      // window.open('https://www.beibao.com','_blank')
      this.$router.push(link)
    },
    getHomeBanner() {
      homeBanner().then((res) => {
        if (res.code == 0) {


          // const result = []
          // for (let i = 0; i < res.data.length; i += 3) {
          //   result.push(res.data.slice(i, i + 3))
          // }
          // this.images = result;
          this.images =res.data;
          // console.log(this.images)
          this.isBanner = false;
        }
      });
    },
    getPlatformList() {
      this.$store.commit("setLoadingShowTrue");
      platformList().then((res) => {
        if (res.code == 0) {
          this.list.push(...res.data);
          this.tabsList.push(...res.data);

          this.sportsComponents = this.list.map(() => ({}));
          this.scrollToItem(this.tabs);
        }
        this.$store.commit("setLoadingShowFalse");
      });
    },
    changeTabs(i) {
      this.tabs = i;
      this.scrollToItem(i);
    },
    scrollToItem(index) {
      this.$nextTick(() => {
        const container = this.$refs.container;
        const item = this.$refs["itemTab-" + index][0];
        if (container && item) {
          const itemOffsetLeft = item.offsetLeft;
          const containerWidth = container.clientWidth;
          const itemWidth = item.clientWidth;

          // 计算滚动位置
          const scrollTo = itemOffsetLeft - containerWidth / 2 + itemWidth / 2;

          container.scrollTo({
            left: scrollTo,
            behavior: "smooth",
          });
        }
      });
    },

    scrollInit() {
      // 获取要绑定事件的元素
      const nav = document.getElementById("navs")
      var flag; // 鼠标按下
      var downX; // 鼠标点击的x下标
      var scrollLeft; // 当前元素滚动条的偏移量
      nav.addEventListener("mousedown", function (event) {
        flag = true;
        downX = event.clientX; // 获取到点击的x下标
        scrollLeft = this.scrollLeft; // 获取当前元素滚动条的偏移量
      });
      nav.addEventListener("mousemove", function (event) {
        if (flag) { // 判断是否是鼠标按下滚动元素区域
          var moveX = event.clientX; // 获取移动的x轴
          var scrollX = moveX - downX; // 当前移动的x轴下标减去刚点击下去的x轴下标得到鼠标滑动距离
          this.scrollLeft = scrollLeft - scrollX // 鼠标按下的滚动条偏移量减去当前鼠标的滑动距离
        }
      });
      // 鼠标抬起停止拖动
      nav.addEventListener("mouseup", function () {
        flag = false;
      });
      // 鼠标离开元素停止拖动
      nav.addEventListener("mouseleave", function () {
        flag = false;
      });
    },
  }
}
</script>
<style lang="less" scoped>
.home {
  // background-image: url('@/assets/home/homeBj.webp');
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: fixed;
  min-height: calc(100vh - 94px);
  // padding-bottom:56px;
  user-select: none;

  .my-splice {
    width: 91.25%;
    height: 396px;
    border-radius: 35px;
    margin: 0 auto 0 auto;

    .lbImg {
      width: 100%;
      height: 396px;
      border-radius: 35px;
    }
  }

  .layers {
    display: flex;
    width: 91.25%;
    min-height: 219px;
    align-items: center;
    justify-content: space-between;
    margin: 45px auto 0 auto;

    img {
      width: 48.2496%;
      min-height: 219px;
      border-radius: 38px;
    }

  }

  .appDownTitleImg {
    width: 76.38888%;
    min-height: 98px;
    margin: 58px auto 0 auto;
  }

  .wybzdss {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 18px auto 0 auto;
    padding-left: 73.33px;
    width: 100%;

    .wybzdImg {
      width: 47.8472%;
      // min-height: 455px;
      flex-shrink: 0;

    }

    .wybzdRight {
      width: 35.49611%;
      min-height: 492px;
      margin-left: 82px;
      padding: 36px 28px 40px 36px;

      .wybzd-tit {
        color: rgba(255, 255, 255, 0.85);
        text-align: justify;
        font-family: "PingFang SC";
        font-size: 49px;
        font-style: normal;
        font-weight: 400;
        margin-bottom: 35px;
        text-align: left;

      }

      .wybzd-con {
        color: rgba(255, 255, 255, 0.65);
        text-align: justify;
        font-family: "PingFang SC";
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        text-align: left;


      }

      .wybzdRight-bottom {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 42px;

        .wybz-right-bottom-demo {
          display: flex;
          width: 194px;
          min-height: 230px;
          padding: 10px;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          flex-shrink: 0;
          border-radius: 24px;
          border: 1px solid #FFF;
          background: #19262E;

          .wybz-bom-in {
            display: flex;
            width: 174px;
            height: 210px;
            padding: 31px 32px 30px 33px;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            flex-shrink: 0;
            border-radius: 18px;
            border: 1px solid rgba(255, 255, 255, 0.45);
            background: #1E303A;

            .erwm {
              width: 99.346px;
              height: 100px;
              flex-shrink: 0;
              margin-bottom: 16px;


            }

            .ewmTxt {
              color: #FFF;
              text-align: center;
              font-family: "PingFang SC";
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              line-height: 24px;
              white-space: nowrap;
              /* 67.708% */
            }

          }

          .bom-in-padd {
            padding: 31px 14px 30px 15px;

            .erwm {
              width: 84px;
              height: 94px;
              margin-bottom: 21px;
            }

            .ewmlink {
              background: linear-gradient(90deg, #2C81FF 8.13%, #2AB3FF 93.75%);
              background-clip: text;
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
              font-family: "SF Pro Display";
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 20.833px;
            }
          }
        }
      }
    }
  }



  .homeContent {
    width: 100%;
    background: rgba(26, 44, 56, 1);
    padding-bottom: 40px !important;

    .lbOut {
      display: flex;
      align-items: center;
      gap: 16px;
      overflow: auto;
      margin-bottom: 20px;
      padding-bottom: 8px;

      .lbDemo {
        flex-shrink: 0;
        width: 445px;
        height: 160px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 8px;
        cursor: pointer;
        border: 4px solid rgba(40, 62, 76, 1);

        img {
          width: 100%;
          height: 100%;

        }
      }
    }

    .lunboOut {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 16px;

      .lunboList {
        //width: 364px;
        // width: 550px;
        // height: 224px;
        width: 356px;
        height: 160px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 8px;
        cursor: pointer;
        border: 4px solid rgba(40, 62, 76, 1);

        img {
          width: 100%;
          height: 100%;
          border-radius: 8px;
        }
      }

    }

    .lbOut::-webkit-scrollbar {
      border-radius: 2px;
      background: #101b1f;
      height: 4px;
      width: auto;
      flex-shrink: 0;
    }

    .lbOut::-webkit-scrollbar-thumb {
      height: 4px;
      width: auto;
      flex-shrink: 0;
      border-radius: 2px;
      background: #283e4c;
    }

    .lunboOut::-webkit-scrollbar {
      border-radius: 2px;
      background: #101b1f;
      height: 4px;
      width: auto;
      flex-shrink: 0;
    }

    .lunboOut::-webkit-scrollbar-thumb {
      height: 4px;
      width: auto;
      flex-shrink: 0;
      border-radius: 2px;
      background: #283e4c;
    }




    .placeholder-tabs {
      width: 100%;
      height: 48px;
      margin: 0px auto 0px auto;

      .tabOut {
        width: 100%;
        height: 49px;
      }

      .tabs {
        width: 57.91%;
        // margin-top: 24px;

        .wrapper {
          width: 100%;
          height: 48px;
          display: flex;
          // justify-content: space-between;
          align-items: center;
          border-radius: 24px;
          background: #15212d;
          gap: 7px;
          padding: 0 5px;
          // transition: all 0.5s ease;
          overflow-x: scroll;
          overflow-y: hidden;
          -webkit-overflow-scrolling: touch;

          // white-space: nowrap;
          .item {
            cursor: pointer;
            transition: all 0.5s ease;
            // min-width: 79px;
            border-radius: 0.10667rem;
            display: flex;
            height: 38px;
            padding: 4px 10px;
            justify-content: center;
            align-items: center;
            white-space: nowrap;
            border-radius: 19px;
            gap: 0.10667rem;
            //   font-family: PingFang SC;
            //   font-size: 0.37333rem;
            //   font-style: normal;
            //   font-weight: 400;
            //   line-height: 120%;
            flex-shrink: 0;
            //   color: #fff;
            color: #fff;
            font-family: "PingFang SC";
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 150%;

            /* 21px */
            &.active {
              // color: #f3b867;
              // font-family: PingFang SC;
              // font-size: 0.37333rem;
              // font-style: normal;
              // font-weight: 600;
              // line-height: 120%;
              white-space: nowrap;
              display: flex;
              height: 38px;
              // padding: 4px 15px;
              padding: 4px 15px;
              align-items: center;
              gap: 5px;
              border-radius: 19px;
              background: #364452;
            }
          }

          .tabImg {
            width: 16px;
            height: 16px;
          }
        }

        ::-webkit-scrollbar {
          /* width: 3px; */
          display: none;
          /* 设置滚动条的宽度 */
        }
      }
    }






  }



}

/deep/.el-carousel__container {
  height: 396px;
}

/deep/.el-carousel__indicators {
  left: 0;
}

@media (max-width: 850px) {
  .layers {
    flex-direction: column;
    gap: 18px;

    img {
      width: 100% !important;
    }
  }

  .wybzdss {
    flex-direction: column;
    padding-left: 10px !important;

    .wybzdImg {
      width: 98% !important;
    }

    .wybzdRight {
      width: 98% !important;
      margin-left: 0 !important;
    }

    .wybzd-con {
      text-align: center !important;
      ;
    }
  }

  .wybzd-tit {
    font-size: 28px !important;
    text-align: center !important;
  }

  .appDownTitleImg {
    width: 100% !important;
  }

  .wybzdRight-bottom {
    justify-content: center !important;
    gap: 18px;
  }
}

@media (max-width: 340px) {
  .wybzdRight-bottom {
    flex-direction: column;
    gap: 18px;
  }
}

/deep/.el-carousel__indicator {
  width: 6px;
  height: 6px;
  margin: 0 5px;

  .el-carousel__button {
    width: 6px;
    height: 6px;
    border-radius: 100%;
    background-color: rgba(47, 63, 74, 1);
    opacity: 1;
  }
}

/deep/.el-carousel__indicators {


  .is-active {
    position: relative;
    height: 6px;
    width: 40px;
    .el-carousel__button {
      border-radius: 100px;
      width: 40px;
      height: 6px;
      position: relative;
      background-color: rgba(47, 63, 74, 1);
      
      @keyframes width {
        0% {
          width: 0%;
        }

        100% {
          width: 100%;
        }
      }

      &::before {
        content: "";
        display: block;
        z-index: 999;
        height: 100%;
        position: absolute;
        background: #4774DA;
        top: 0;
        left: 0;
        bottom: 0;
        animation: width 5s linear;
        transition: all 5s;
        border-radius: 100px;
      }
    }
  }
}
</style>
